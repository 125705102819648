@import '../../Styles/global-imports.scss';

.event-control {
    .title-logo-container {
        flex: 1;
        margin-right: 1em;
    }

    position: relative;

    .eventTable {
        height: 74vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .bulk-edit-block {
        position: absolute;
        bottom: 15px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        background: $darkBlueNormal;
        width: 95%;
    }

    .table {
        th, td {
            font-size: 12px;
            white-space: normal;
        }

        tr {
            &:last-of-type {
                td {
                    &:last-of-type {
                        border-bottom-right-radius: 6px;
                    }

                    &:first-of-type {
                        border-bottom-left-radius: 6px;
                    }
                }
            }
        }

        width: 100%;
        table-layout: fixed;
    }

    .event-toolbar {
        background: $darkBlueNormal;
        padding: 1.3em;
        border-radius: 8px
    }

    .login-footer {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 120px;
        left: 0;
        right: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .event-title {
        .event-dropdown {
            div {
                white-space: break-spaces;
                text-align: left;
            }

            background: none !important;
            min-width: 150px;
            width: auto;
        }
    }
    background-image: url('../../images/events/background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    overflow: hidden;
}