@import '../../Styles/global-imports.scss';


// Stonegate Branding
$statsBg: #D9D9D9;
$ctaBg: #7F7384;
$orange: #FD5D03;

$tableHeader: #4A4A4A;
$tableBody: rgb(240, 240, 240);

.rating-row {
    white-space: nowrap !important;
}

.manage-security-modal {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
        .manager-reports-container {
            .report-table-container {
                margin-bottom: 0;
            }

            overflow: hidden;
        }

        .roster-main-table {
            .ReactVirtualized__Table__headerRow {
            }

            .ReactVirtualized__Table__row {
                width: 1180px !important;
            }

            overflow-x: scroll;
        }

        .left-controls {
            margin-bottom: 1em;
        }

        .statistics-right {
            margin-bottom: 0 !important;
        }
    }

    .roster-table {
        .ReactVirtualized__Table__Grid, .RosterSummaryTableRow, .ReactVirtualized__Grid__innerScrollContainer {
            overflow: visible !important;
            width: 10345px !important;
        }
    }

    .btn:disabled {
        background: $grayBg;
        opacity: 0.6;
    }

    .toast {
        margin-bottom: 1.3em;
        width: 100%;
    }

    .ReactVirtualized__Table__row {
        width: 100% !important;
    }

    .roster-main-table {
        .ReactVirtualized__Table__Grid, .ReactVirtualized__Grid__innerScrollContainer, .mangerTableRow {
            width: 1170px !important;
            max-width: 1170px !important;
        }
    }
    /*.ReactVirtualized__Table__Grid, .ReactVirtualized__Grid__innerScrollContainer {
        width: 1043px !important;
        max-width: 1043px !important;
    }*/
    .dropdown-item.active {
        background-color: none;
    }

    .mangerTableRow {
        /*        width: 1043px !important;
*/ overflow: visible;
    }

    .btn-primary {
        white-space: nowrap;
    }

    .modal .show {
        justify-content: center !important
    }

    .mobile-card {
        background: white;
        color: $darkBlueNormal;
    }

    .manage-security-container {
        width: 100%;
    }

    .dropdown {
        button {


            &:hover {
                color: $PinkNormal !important;
            }

            color: black;
        }
    }

    .dropdown-menu.show {
        .dropdown-item {
            &:hover {
                color: $PinkNormal;
            }

            background: white;
        }

        max-height: 55vh !important;
    }

    .ReactVirtualized__Table__grid {
        border-bottom-left-radius: 6px;
    }

    .ReactVirtualized__Table__headerRow {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    .ReactVirtualized__Table__headerColumn {
        margin-right: 0 !important;
    }

    .ReactVirtualized__Table__row {
        &:hover {
            background-color: $darkBlueNormal !important;
            color: white;
            @include transition;
        }

        background: white;
        color: $darkBlueDark;
        @include transition;
        position: relative;
        border-bottom: 1px solid #b9b9b9;
    }

    .add-shift-row {
        button {
            line-height: 1;
        }

        margin-top: 29px;
    }

    .statistics-right, .add-shift-row {
        background: $grayBg;
        border-radius: 6px;
    }

    .dropdown-toggle {
        color: black;
        width: auto;
        margin-right: auto;
    }

    .modal-dialog {
        @media (max-width: 575.98px) {
            width: 100% !important;
            margin: 0;
            height: 100%;
            max-width: 100%;
            min-width: auto;
        }

        width: 95%;
        max-width: 1530px;
        /*        min-width: 1167.7px;
*/
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
        //your styles here
        align-items: baseline !important;

        .modal-dialog {
            width: 96%;
        }
    }

    .small-spinner,
    .small-spinner span {
        box-sizing: border-box;
    }

    .small-spinner {
        display: inline-block;
        width: 40px;
        height: 40px;
        position: relative;
        left: 50%;
        margin-left: -16px;
    }

    .small-spinner span {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 32px;
        height: 32px;
        margin: 8px;
        border: 4px solid currentColor;
        border-radius: 50%;
        animation: small-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: currentColor transparent transparent transparent;
    }

    .small-spinner span:nth-child(1) {
        animation-delay: -0.45s;
    }

    .small-spinner span:nth-child(2) {
        animation-delay: -0.3s;
    }

    .small-spinner span:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes small-spinner {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
    // New table styling
    .mangerTableRow {
        &:hover {
            background-color: $darkBlueNormal !important;
            color: white;
            @include transition;
        }

        @include transition;
        position: relative;
        border-bottom: 1px solid #b9b9b9;
    }

    .category-column {
        overflow: unset !important;
    }

    .role-column {
        word-break: break-all;
    }

    .GipPig-Modal {
        height: 600px;
        display: flex;
        flex-direction: column;
        margin: 0 1em 2em 1em;
    }

    .search-bar-container {
        margin-bottom: 8px;
    }

    .search-input {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 10px;
    }

    .list-container {
        height: calc(100% - 48px);
        overflow-y: auto;
    }

    .ReactVirtualized__Table {


        .notes {
            white-space: normal;
        }

        &__headerColumnn {
            padding: 10px;
            border-right: 1px solid white !important;
        }

        &__rowColumn {
            font-size: 14px;
            width: auto !important;
            white-space: normal;
            border-right: 1px solid #b9b9b9;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 0px !important;
            padding: 10px;
        }

        &__headerRow {
            background: $tableHeader;
            min-height: 50px;
            height: auto;
            font-size: 14px;
            color: white;
            text-transform: unset !important;
            font-weight: normal !important;
        }

        &__headerTruncatedText {
            white-space: normal !important;
        }
    }

    .mobile-collapse {
        Button {
            flex: 1;
            height: 45px;
        }

        justify-content: space-between;
    }

    .gigpig-btn {
        &:hover {
            background: #aa256e !important;
        }

        svg {
            width: auto;
            height: auto;
        }

        height: 45px;
        white-space: nowrap;
    }
    // New table styling END
    .report-notice {
        padding: 15%;

        svg {
            height: 80px;
            width: 80px;
        }
    }

    .statistic {
        @media (max-width: 575.98px) {
            justify-content: space-between;
            width: 100%;
            margin-right: 0 !important;
        }
    }

    .report-header {
        .form-label {
            font-size: 14px;
            color: white;
        }

        .statistics-bar {
            label {
                font-size: 0.87rem;
            }

            &__item {
                &:last-of-type {
                    border: none;
                }

                padding-right: 1em;
                margin-right: 1em;

                @media (max-width: 575.98px) {
                    border: none;
                    padding: 0.1em 0.8em;
                    margin: 0;
                    justify-content: space-between;
                }
            }

            border-radius: 6px;
            background: white;
        }
    }

    .FilterClearButton {
        &:hover {
            cursor: pointer;
        }
    }

    .stat-header {
        border-bottom: 1px solid $grayLight;
    }

    .css-1nmdiq5-menu {
        z-index: 9999 !important;
    }

    .stonegate-highlight {
        td {
            background: #00FFFF !important;
        }
    }

    body {
        background-image: url('../../images/manager-report/opus-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        padding-bottom: 2em;
    }

    .custom-tooltip {
        position: absolute;
        background-color: $PinkNormal !important;
        color: white;
        padding: 5px 10px !important;
        border-radius: 5px;
        z-index: 1000;
        display: none; /* Initially hidden */
        /* Position to the right */
        top: 50%;
        left: 100%;
        z-index: 9999;
        transform: translateY(-50%);
        margin-left: 10px; /* Adjust space between target and tooltip */
    }

    .custom-tooltip.show {
        display: block;
        background-color: $PinkNormal !important;
    }

    .custom-tooltip::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -5px; /* Adjust based on the size of the arrow */
        transform: translateY(-50%) rotate(45deg);
        width: 10px; /* Arrow size */
        height: 10px; /* Arrow size */
        background-color: $PinkNormal !important;
        border-radius: 2px;
    }

    .stonegate-container {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 575.98px) {
        @supports (height: 100svh) {
            .stonegate-container {
                height: calc(100svh - 11rem);
            }
        }
    }

    .custom-tooltip {
        position: absolute;
        background-color: black;
        color: white;
        padding: 5px;
        border-radius: 5px;
        z-index: 1000;
        display: none; /* Initially hidden */
        /* Position to the right */
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        margin-left: 10px; /* Adjust space between target and tooltip */
    }

    .custom-tooltip.show {
        display: block;
    }

    .tableKey {
        p {
            @media (max-width: 575.98px) {
                font-size: 10px;
            }
        }
    }


    .custom-tooltip::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -5px; /* Adjust based on the size of the arrow */
        transform: translateY(-50%) rotate(45deg);
        width: 10px; /* Arrow size */
        height: 10px; /* Arrow size */
        background-color: black;
        border-radius: 2px;
    }

    .stonegate-container {
        height: 100vh;
    }

    .export-button {
        &:hover {
            background: #aa256e !important;
        }
    }

    .apply-button {
        @include transition;

        @media (max-width: 575.98px) {
            width: 100% !important;
        }
    }

    .clear-button {
        background: none !important;
        color: white !important;
        border: 1px solid white;

        @media (max-width: 575.98px) {
            width: 100% !important;
        }
    }

    .btn.btn-stonegateCta {
        &:hover {
            background: #3D3740;
            color: white;
        }

        @include transition;
        color: white;
        background: $orange;
        padding: 0.5em 1.2em;
    }

    .report-filters {
        .dropdown-menu.show {
            max-height: 50vh !important;
        }

        .form-check {
            .form-check-label {
                color: white;
                font-size: 13px;
            }

            margin-bottom: 0.6em;
        }

        .dropdown {
            min-width: 130px;
        }

        input {
            @media (max-width: 575.98px) {
                margin-bottom: 0.8em;
            }
        }

        @media (max-width: 575.98px) {
            align-items: flex-start !important;
        }
    }

    .header-logos {
        @media (max-width: 575.98px) {
            img {
                width: 100%;
                max-width: 280px;
            }
        }

        img {
            width: 100%;
        }

        max-width: 280px;
    }

    .report-table-container {
        @media (max-width: 575.98px) {
            ::-webkit-scrollbar {
                display: none !important;
                width: 0px !important;
                height: 0px !important;
            }
        }
    }

    .report-controls, .report-filters {
        @media (max-width: 575.98px) {
            .dropdown {
                flex: 1 1 0px;
            }
        }

        .checked {
            background: #ddd !important;
        }

        button.dropdown-toggle {
            width: 100%;
            height: 45px;
        }

        .btn-clear-button {
            &:hover {
                .radio-circle {
                    border-color: white;
                }

                color: #333;
                background: #ddd;
            }

            @include transition;
            background: none;
            border: 1px solid #ced4da;
            border-radius: 6px !important;
            color: white;
            padding: 0.36em 0.8em;
            height: 45px;

            @media (max-width: 575.98px) {
                &:last-of-type {
                    margin-bottom: 0;
                }

                width: 100%;
                margin-bottom: 0.8em;
            }
        }

        @media (max-width: 575.98px) {
            margin: 0;
            width: 100%;
        }
    }

    .scrollButtonSide {
        svg {
            -webkit-animation: fadeinout 3s linear infinite forwards;
            animation: fadeinout 3s linear infinite forwards;
            width: 30px !important;
            height: 30px !important;
        }

        @include transition;
        background: $PinkNormal;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .scrollButtonSideBack {
        svg {
            -webkit-animation: fadeinout 3s linear infinite forwards;
            animation: fadeinout 3s linear infinite forwards;
            width: 30px !important;
            height: 30px !important;
        }

        @include transition;
        background: $BlueNormal;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .manager-reports-container {
        /*    ::-webkit-scrollbar {
        position:absolute;
        width: 10px;
        height: 7px;
    }*/
        .dropdown-toggle {
            margin-right: 0;
        }

        .percent {
            font-size: 12px;
        }

        .report-table-container {
            .notes {
                min-width: 200px;
                max-width: 200px;
                white-space: pre-wrap;
            }

            height: 100%;
            width: 100%;
            overflow-y: hidden;
            overflow-x: scroll;
            scroll-behavior: smooth;
            border-radius: 6px;
            border: 1px solid #ddd;
            padding-bottom: 1em;

            @media (max-width: 575.98px) {
                border: none;
                overflow: hidden;
            }
        }

        .table-message {
            display: flex;
            justify-content: center;
            padding-top: 30px;
            padding-bottom: 30px;
            font-weight: bold;
        }

        border-radius: 6px;
        flex-grow: 1;
    }

    .header-row {
        svg {
            width: 25px;
            height: 25px;
            margin-left: 2em;
        }

        padding: 0.5em;
    }

    .btn-focus-button {
        color: white !important;
        padding: 0px !important;
        font-size: 13px;
    }

    .mobile-row {
        .mobile-col {

            &:last-of-type {
                border-right: none;
            }

            border-right: 1px solid #b9b9b9;
            width: 50%;
            padding: 0.2em 0.5em;
        }

        p {
            font-size: 12px;
        }

        &:last-of-type {
            border-bottom: 0;
        }

        border-bottom: 1px solid #b9b9b9;
    }

    .ReactVirtualized__Grid__innerScrollContainer {
        gap: 1em;
    }

    .ReactVirtualized__List {
        border-radius: 6px;
    }

    .mobile-card {
        @media (max-width: 575.98px) {
            .mobile-card-inner {
                border-radius: 6px;
                background: white;
                height: 100%;
            }
        }

        border-radius: 6px;
        padding-bottom: 1em;
    }

    .elilipses-non-paragraph {
        div {
            white-space: nowrap !important;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .elilipses {
        div {
            p {
                white-space: nowrap !important;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            width: 300px;
        }
    }


    @-webkit-keyframes fadeinout {
        0%,100% {
            opacity: 0.25;
        }

        50% {
            opacity: 1;
        }
    }

    @keyframes fadeinout {
        0%,100% {
            opacity: 0.25;
        }

        50% {
            opacity: 1;
        }
    }