@import '../../Styles/global-imports.scss';

.two-col-filters {
    min-width: 600px;
}

.modal-backdrop.show {
    opacity: 0.9 !important;
}

.modal.show {
    display: flex !important;
    align-items: center;
}

.bulk-update-modal {
    .bulk-update-error {
        margin: 0 1.7em;
        border-radius: 6px;
        color: red;
        background: white;
    }

    .currently-selected {
        .remove-selected {
            &:hover {
                svg {
                    fill: $PinkNormal;
                }

                cursor: pointer;
            }

            @include transition;
            padding: 0.3em 0;
        }

        max-height: 385px;
        overflow-Y: scroll;
    }

    .modal-dialog {
        min-width: 970px !important;
    }

    .form-group {
        &:first-of-type {
            button {
                width: calc(100% - 15px);
            }
        }

        &:last-of-type {
            button {
                width: 100%;
            }
        }

        input {
            height: 38px;
            width: 100%;
        }

        max-width: 50%;
    }
}

.filterbar-container {
    .darkmode-toggle {
        margin-left: 1em;

        label {
            color: black;
        }
    }

    .filters-dropdown {
        .form-control, button, .dropdown-toggle {
            width: 100%;
        }

        .form-group {
            margin: 1em 0;
        }

        label, .dropdown-item a {
            color: #333;
        }

        border: none;
    }

    .filters-nav {
        height: 38px;
    }

    .dropdown-toggle {
        margin-top: 0;
    }

    .form-switch {
        input {
            height: 25px;
            width: 45px;
            margin-right: 0.5em;
        }

        height: 32px !important;
        display: flex !important;
        align-items: center;
    }

    background: white;
    margin-top: 75px;
}

.pagination-button {
    &:hover {
        background: $PinkLight;
        color: #333;
    }

    color: white;
    border: none;
    background: $PinkNormal;
    padding: 5px 10px;
    border-radius: 15px;
    @include transition;
}

.search-container {
    padding: 1em 0;
}

.applied-filters {
    p {
        @include themed() {
            color: t($text);
        }
    }

    padding: 1em 0;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/*

    light mode table styling

*/
a {
    &:hover {
        cursor: pointer;
    }
}

.filterbar-container {
    @include themed() {
        background: t($nav-alt-bg);
        color: t($text);
    }
}


tbody > tr {
    &:hover {
        td {
            @include themed() {
                background: t($col-hover) !important;
            }
        }
    }
}

table {
    tbody {
        @include themed() {
            background-color: t($table-bg);
        }
    }

    @include themed() {
        border-color: t($table-border);
    }

    border-spacing: 0;
    border-collapse: separate !important;
    border-width: 0;
    border-style: solid;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
}

.main-tableheader {
    @include themed() {
        background: t($table-head);
    }
}

tbody, td, tfoot, th, tr {
    .btn-primary {
        &:hover {
            @include transition;

            @include themed() {
                background: t($table-btn-hover) !important;
            }
        }

        @include transition;

        @include themed() {
            background: t($table-btn-bg) !important;
        }
    }

    vertical-align: middle;
    border-style: solid;
    border-width: 1px !important;
    text-align: left;
    @include font-size(14px);
    @include transition;

    @include themed() {
        border-color: t($table-border);
    }
}

.custom-table-body {
    td[data-column-name="selection"],
    td[data-column-name="staffID"],
    td[data-column-name="lastname"],
    td[data-column-name="firstname"] {
        outline-width: 1px;
        outline-style: solid;

        @include themed() {
            background-color: t($table-bg);
            color: t($text);
            outline-color: t($table-border);
        }
    }

    tbody {
        td, a, svg {
            @include themed() {
                color: t($text)
            }
        }
    }

    @include themed() {
        background: t($table-bg);
    }
}

th {
    max-width: 150px;
    white-space: normal;

    @include themed() {
        background: t($table-head);
    }
}
/*** Make Certain columns fixed ***/

.custom-table-body {
    :is(th, td)[data-column-name="selection"] {
        position: sticky;
        left: 0;
        z-index: 1;
    }

    :is(th, td)[data-column-name="staffID"] {
        position: sticky;
        left: 110px;
        z-index: 1;
    }

    :is(th, td)[data-column-name="lastname"] {
        position: sticky;
        left: 220px;
        z-index: 1;
    }

    :is(th, td)[data-column-name="firstname"] {
        position: sticky;
        left: 330px;
        z-index: 1;
    }

    height: 80vh !important;
    overflow: scroll;
    padding-right: 0.5em;
    border-radius: 10px;
}

thead {
    tr {
        height: 70px;
    }

    text-align: center;
    position: sticky;
    top: 0;
    z-index: 15;
}

.custom-table-body-empty {
    padding-right: 0.5em;
    border-radius: 10px;

    @include themed() {
        background: t($bg);
    }
}

th {
    white-space: nowrap;
    color: white !important;
    text-align: left;
    line-height: 1.4;
}

th,
td {
    font-weight: unset;
    padding: 10px;
    text-align: left;
}

td {
    min-width: 110px;
    white-space: nowrap;
    line-height: 1.4;
    text-align: left;
    padding-bottom: 5px;
    padding-top: 5px;

    @include themed() {
        color: t($text);
    }
}

.Filter_Header th {
    max-width: 100%;
    white-space: nowrap;
}

.container-table {
    margin: 0 1em 2em 1em;
}

.custom-table {
    border-radius: 10px;
    overflow: hidden;
    border-collapse: separate;
}
/*** Table style end ***/
/*** Custom scrollbar style start ***/
:is(.custom-table-body)::-webkit-scrollbar {
    height: 1em;
    border-radius: 100vw;
}

:is(.custom-table-body, .dropdown-menu.show)::-webkit-scrollbar-thumb {
    border-radius: 100vw;
    background: #333;
}

:is(.custom-table-body, .dropdown-menu.show)::-webkit-scrollbar-thumb:hover {
    background: grey;
}

*::-webkit-scrollbar-corner,
.dropdown-menu.show::-webkit-scrollbar-track {
    background-color: transparent;
}

.custom-table-body::-webkit-scrollbar-track {
    background-color: darkgrey;
    border-radius: 100vw;
    border: 1px solid #333;
}

.dropdown-item-group {
    display: flex;
    justify-content: space-between;
}

.modal_btn_corner {
    position: absolute;
    right: 25px;
    bottom: 10px;
}

.label_space {
    margin-right: 0px;
}
/*** Custom scrollbar style end ***/
/*** filter header style start ***/
.Filter_Header {
    border-radius: 10px;
    max-width: 50%;
    top: 6em;
    position: relative;
}



div.app_date_container {
    width: 16.4em;
}

.range-slider {
    width: 100%;
}

.advance-search {
    top: 7.5em;
    left: 10.6em;
    justify-content: flex-end;
}

button.datebutton {
    width: 200px;
}

.buttonhide {
    background: transparent !important;
    border: none;
}
/*** filter header style end ***/

.pagination {
    gap: 12px;
    justify-content: center;
    align-items: center;
    margin: 0 2em 0 2em;
    flex-wrap: wrap;
}

.pagination > select, input[type="number"] {
    word-wrap: normal;
    width: 100px;
    text-align: center;
    height: 20px;
    border: none;
}
/*** pagination footer style end ***/

.ScreeningTable {
    min-height: 100vh;
    background-position: center;
    background-attachment: unset;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;

    @include themed() {
        color: t($text);
    }
}

.custom-message {
    text-align: center;
    @include absolutecenter(both);
}

.filter_inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    justify-content: center;
    align-items: flex-end;
}

.dropdown-toggle {
    margin-right: 1em;
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
}

button.saved-filters {
    width: auto !important;
}

input[type="number"], input[type="date"] {
    border-radius: 6px;
}

select {
    border-radius: 6px;
}

.date-input {
    &:hover {
        background: white !important
    }

    border: 1px solid #ddd !important;
    background: white !important;
    padding-left: 10px;
}

button:hover {
    cursor: pointer;
}

.dropdown-menu.show {
    .dropdown-item {
        &:hover {
            background: $blueLight;
            border-radius: 3px;
        }

        color: #333;
        @include transition;
    }

    .dropdown-checkbox {
        margin-right: 0.5em;
    }

    .search-input {
        width: 100%;
        margin-bottom: 0.8em;
        padding: 8px 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    padding: 1em;
    color: #333;
    overflow-y: auto;
    background: white;
    border-radius: 6px;
    border: none;
    overflow: scroll;
    max-height: 85vh;
}

.dropdown-menu {
    --bs-dropdown-link-hover-bg: #0c022f !important;
    border-radius: 3px;
    color: white;
    @include transition;
}

.react-datepicker-wrapper {
    display: block;
}

.react-datepicker__navigation {
    width: 10% !important;
    margin-top: 0.5em;
}

.react-datepicker-popper {
    z-index: 16;
    border: none !important;
    padding: 0 !important;
    background-color: transparent !important;
}

.react-datepicker__input-container {
    max-width: 100%;
    width: 100%;
}

.model-table-container {
    thead {
        background: $PinkNormal;
        text-align: left;
        outline: 1px solid $PinkNormal;
    }

    td {
        text-align: left;
        outline: 1px solid #333;
    }
}

.modal-content {
    .custom-table {
        border-radius: 10px;
        overflow: scroll;
        height: 60vh;
        background: #2F2F2F
    }

    table {
        thead {
            background: #474747;
        }

        th {
            border-color: #4b4b4b;
        }

        td {
            border-color: #4b4b4b;
        }
    }

    background-position: center;
    background-color: $darkBlueNormal !important;
    color: white !important;
    box-shadow: -5px 5px 10px 0px rgba(0, 0, 0, 0.30);
}

.modal-body {
    padding: 1em;
    display: flex;
    justify-content: center;
}

.modal-header, .modal-footer {
    border: none !important;
}

input[type=range].range-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    border: none;
    padding: 0px;
    background: linear-gradient(to right, #f2f2f2 0%, #f2f2f2 100%);
    outline: none;
    margin: 30px 0 0 0;
}

input[type='range'].range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    padding: 0px;
    border-radius: 50%;
    background-color: #fff;
    border: 4px solid #005DE5;
}

.range-slider__tooltip__label, .range-slider__tooltip__caret {
    display: none !important;
}

.custom-tooltip .tooltip-inner {
    background: $darkBlueNormal;
    color: white; /* Text color */
}

.custom-tooltip .tooltip-arrow::before {
    background: linear-gradient(-68deg,#00AFFA,#0C022F );
}

.referrer-input {
    padding: 0.2em 0em 0.2em 1em;
    width: 100px;
    height: 2rem;
    text-overflow: ellipsis;
    border: 1px solid #ced4da;
}


.header-svg-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: pre-line;
}

svg {
    width: 23px;
    height: 18px;
}

.svg-icon {
    cursor: pointer;
}


.fixed-width {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
}

button.dropdown-toggle {
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

button.c247_dropdown {
    width: auto;
}

.model-table-container {
    border-radius: 10px;
}

input#filterName {
    width: 400px;
}


.ml-5 {
    margin-left: 5em !important;
}

svg.custom-icon {
    width: 3em;
    height: 3em;
}
/***** Media Query Start ******/
@media only screen and (max-width: 1400px) {
    .submit-container {
        left: 0;
        bottom: 0;
        flex-direction: row;
        align-items: flex-end;
    }
}

@media only screen and (max-width: 1565px) {

    .filter-container-laptop {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100px;
    }
}
/***** Media Query End ******/
